body {
  margin: 0;

  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Heebo', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-layout-sider-zero-width-trigger.ant-layout-sider-zero-width-trigger-left {
  top: 0;
}

/* Estilos para ant-menu con colores personalizados */
.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-dark {
  background-color: #1C3661 !important;
  /* Gris oscuro */
}

.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-dark .ant-menu-item {
  color: #ffffff;
  /* Color del texto en los items del menú */
}

/* Estilo para los elementos del menú cuando se pasa el mouse por encima */
.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-dark .ant-menu-item:hover {
  background-color: #34495e !important;
  /* Azul oscuro al pasar el mouse */
}

/* Estilo para el contenedor de los elementos del sider */
.ant-layout-sider-children {
  background-color: #1C3661 !important;
  /* Gris oscuro */
}

.ant-layout-sider-zero-width-trigger .ant-layout-sider-zero-width-trigger-left {
  background-color: #1C3661 !important;
}

/* Estilos para ant-layout-sider-zero-width-trigger con color personalizado */
.ant-layout-sider-zero-width-trigger.ant-layout-sider-zero-width-trigger-left {
  background-color: #2c3e50;
  color: white;
  top: 0;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

/* Estilo adicional si es necesario */
.ant-layout-sider-zero-width-trigger.ant-layout-sider-zero-width-trigger-left:hover {
  background-color: #34495e;
  /* Azul oscuro al pasar el mouse */
}

.ant-menu-dark.ant-menu-inline-collapsed {
  background-color: #1C3661 !important;
}

.ant-layout-sider-trigger {
  background-color: #1C3661 !important;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100dvh;
}


/* Modifica el uso del Sider cuando es mobile, ajustando el tamaño del contenido para que no se empuje */
@media only screen and (max-width: 767.99px) {
  .ant-layout {
    min-width: 300px;
  }
}


.ant-table-thead>tr>th {
  border-radius: 0 !important;
  /* Quita el border-radius del encabezado */
}

input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px white inset;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

*:focus {
  outline: none !important;
}


.ant-card-meta-title {
  white-space: pre-line !important;
}

/* .ant-card .ant-card-body {
  padding: 8px !important;
  border-radius: 0 0 8px 8px !important;
} */


@keyframes breathing {
  0%, 100% {
    transform: scale(1);
    /* Tamaño normal */
    opacity: 1;
    /* Totalmente visible */
  }

  50% {
    transform: scale(1.1);
    /* Tamaño ligeramente más grande */
    opacity: 0.8;
    /* Más tenue */
  }
}


.ant-menu-dark .ant-menu-item-selected,
.ant-menu-dark>.ant-menu .ant-menu-item-selected {
  background-color: #254E84 !important;
}


/* Estilo para las notificaciones con icono (openNotification)*/
.ant-notification-notice-with-icon {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  text-align: left !important;
  word-wrap: break-word !important;
}

/* Evitar el bounce en contenedores no scrollables */
html, body {
  overscroll-behavior: none;
}