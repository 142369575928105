.login-wrapper {
    /* background-image: url("../../assets/login-3.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; */
    height: 100dvh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, .1);
}

.login-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.login-footer {
    background-color: transparent;
    width: 95%;
    text-align: center;
    padding: 10px 10px;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: grey;
}

.error-wrapper {
    height: 45px;
    color: red;
}

.form-label {
    font-weight: 500;
}

.about-innercor {
    cursor: pointer;
}

.about-innercor:hover {
    text-decoration: underline;
}

.header-login-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: baseline;
    padding-bottom: 32px;
    margin-bottom: 20px;
}



.card-wrapper {
    width: 100%;
    max-width: 400px;
    min-width: 380px;
    height: 400px;
    align-items: flex-end;
    background-color: #f5f5f5;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
}

@media (max-width: 480px) {
    .card-wrapper {
        width: 100%;
        min-width: 100%;
        height: auto;
    }
}

.legal-version-wrapper {
    display: flex;
    justify-content: space-evenly;
    width: 280px;
}

.profile-dropdown {
    border-radius: 8px;
    background-color: #ffffff;
    padding: 16px;
    width: 300px;
    box-shadow:
        0 6px 16px 0 rgba(0, 0, 0, 0.08),
        0 3px 6px -4px rgba(0, 0, 0, 0.12),
        0 9px 28px 8px rgba(0, 0, 0, 0.05)
}

.profile-header-dropdown {
    display: flex;
    align-items: center;
    padding: 12px;
    justify-content: space-evenly;
}